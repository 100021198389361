<template>
   <ul class="filter">
      <li v-for="(filter, filterIdx) in filters" :key="filter.type" class="filter__item">
         <button
            :data-filter="filter.type"
            @click="showSelectedFilter(filter.type)"
            class="filter__dropdown-header dropdown-header btn"
         >
            {{ filter.selectedProp || filter.title }}
         </button>

         <ul :class="{ 'displayed-filter': filter.type === selectedFilter }" class="dropdown-body">
            <li
               v-for="(prop, propIdx) in filter.props"
               :key="prop.name"
               class="dropdown-body__item"
            >
               <button
                  :disabled="filter.selectedProp === prop.name"
                  :class="{ 'filter--active': filter.selectedProp === prop.name }"
                  @click="checkQueryParam(`f${filterIdx}-p${propIdx}`)"
                  class="dropdown-body__btn btn"
               >
                  {{ prop.name }}
               </button>
            </li>
         </ul>
      </li>
   </ul>
</template>

<script>
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'ProductFilters',

      emits: {
         'selected-filter': (value) => typeof value === 'object'
      },

      data() {
         return {
            filters: [
               { title: 'Размер', type: 'sizes', props: [], selectedProp: '' },
               { title: 'Цвет', type: 'colors', props: [], selectedProp: '' },
               { title: 'Бренд', type: 'brands', props: [], selectedProp: '' },
               { title: 'Сортировать по', type: 'sort', props: [], selectedProp: '' }
            ],
            selectedFilter: ''
         };
      },

      created() {
         this.fetchFilters();
      },

      mounted() {
         document.addEventListener('click', this.closeDropdownList);
      },

      beforeUnmount() {
         document.removeEventListener('click', this.closeDropdownList);
      },

      methods: {
         ...mapActions('filters', ['fetchFilters']),

         showSelectedFilter(filterType) {
            this.selectedFilter = this.selectedFilter !== filterType ? filterType : '';
         },

         closeDropdownList(e) {
            if (!e.target.dataset.filter) {
               this.selectedFilter = '';
            }
         },

         checkQueryParam(param, page = 1) {
            this.filters.forEach((item) => {
               item.selectedProp = '';
            });

            if (param) {
               this.createSearchParams(param, page);
            } else {
               this.$emit('selected-filter', { filter: param, section: '', value: '', page });
            }
         },

         createSearchParams(queryParam, page) {
            const regExp = /f\d+-p\d+/g;
            let searchParams = { filter: queryParam, section: 'category', value: queryParam, page };

            if (regExp.test(`${queryParam}`)) {
               const [filterIdx, propIdx] = queryParam.split('-');

               const filter = this.filters[filterIdx.slice(1)];
               const prop = filter?.props[propIdx.slice(1)];

               if (prop) {
                  filter.selectedProp = prop.name;

                  searchParams = {
                     filter: queryParam,
                     section: filter.type,
                     value: filter.type !== 'sort' ? prop.name : prop.sorting,
                     page
                  };
               }
            }

            this.$emit('selected-filter', searchParams);
         }
      },

      computed: {
         ...mapState('filters', { receivedFilters: 'filters', errorMessage: 'errorMessage' }),

         currentPage() {
            const { page } = this.$route.query;

            return !isFinite(page) || +page <= 0 ? null : +page;
         }
      },

      watch: {
         errorMessage() {
            this.checkQueryParam(this.$route.query.filter, this.currentPage);
         },

         receivedFilters(value) {
            value.forEach((item) => {
               const filter = this.filters.find((filter) => filter.type === item.type);

               if (filter) {
                  filter.props = item.props;
               }
            });

            this.checkQueryParam(this.$route.query.filter, this.currentPage);
         },

         '$route.query.filter'(value) {
            const { name } = this.$route;

            if (name === 'Catalog') {
               this.checkQueryParam(value, this.currentPage);
            }
         },

         '$route.query.page'() {
            const { name, query } = this.$route;

            if (name === 'Catalog') {
               this.checkQueryParam(query.filter, this.currentPage);
            }
         }
      }
   };
</script>
