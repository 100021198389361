<template>
   <footer class="card__footer">
      <button @click="addProductToFavorites" class="card__favorites btn">
         <svg-icon-template :icon-name="'favorites'" alt="Добавить в избранное" />
         <span>В избранное</span>
      </button>

      <button @click="buyProduct" class="card__fast-buy btn">Купить в 1 клик</button>
   </footer>
</template>

<script>
   import SvgIconTemplate from '@/components/templates/SvgIconTemplate';
   import { mapActions } from 'vuex';

   export default {
      name: 'CardFooter',

      components: {
         SvgIconTemplate
      },

      props: {
         product: { type: Object, required: true }
      },

      inject: ['openModal'],

      data() {
         return {
            item: {}
         };
      },

      methods: {
         ...mapActions('favorites', ['saveProductState']),

         addProductToFavorites() {
            this.saveProductState({ product: this.item, isRemove: false });

            this.openModal({ name: 'ModalFavorites', isActive: true });
         },

         buyProduct() {
            this.$store.commit('fastBuy/ADD_PRODUCT', this.item);

            this.openModal({ name: 'ModalFastBuy', isActive: true });
         }
      },

      computed: {
         imageDescription() {
            return `Товар ${this.product.name}`;
         },

         imagePath() {
            return require(`@/assets/images/products/${this.product.images.folder}/${this.product.preview}`);
         }
      },

      watch: {
         product: {
            handler(value) {
               this.item = { ...value };
               this.item.image = this.imagePath;
               this.item.imageDescription = this.imageDescription;
            },
            immediate: true
         }
      }
   };
</script>
