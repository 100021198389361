<template>
   <article :style="{ '--min-card-height': minCardHeight }" class="card">
      <div class="card__wrap">
         <img :src="imagePath" :alt="imageDescription" class="card__img" />

         <h3 class="card__title swiper-no-swiping">
            <router-link :to="{ name: 'Product', params: { id: product.id } }" class="card__link">
               {{ product.name }}
            </router-link>
         </h3>

         <p class="card__subtitle swiper-no-swiping">Артикул: {{ product.vendorCode }}</p>

         <slot name="parameters"></slot>

         <div class="card__price price">
            <p class="price__text swiper-no-swiping">
               Цена: <span>{{ currency }}&nbsp;{{ product.price }}.00</span>
            </p>

            <button @click="addProductToCart" class="price__cart btn">
               <cart-icon />
            </button>
         </div>

         <slot name="footer"></slot>
      </div>
   </article>
</template>

<script>
   import CartIcon from '@/components/Icons/CartIcon';
   import { mapActions } from 'vuex';

   export default {
      name: 'ProductCard',

      components: {
         CartIcon
      },

      props: {
         product: { type: Object, required: true }
      },

      inject: ['openModal'],

      data() {
         return {
            item: {},
            minCardHeight: 0
         };
      },

      mounted() {
         const ro = new ResizeObserver((entries) => {
            this.minCardHeight = `${entries[0].contentBoxSize[0].blockSize}px`;
         });

         ro.observe(this.$el);
      },

      beforeUpdate() {
         if (!this.minCardHeight) {
            this.minCardHeight = getComputedStyle(this.$el).height;
         }
      },

      methods: {
         ...mapActions('cart', ['saveProductState']),

         addProductToCart() {
            this.saveProductState({ product: this.item, isRemove: false });

            this.openModal({ name: 'ModalCart', isActive: true });
         }
      },

      computed: {
         imageDescription() {
            return `Товар ${this.product.name}`;
         },

         imagePath() {
            return require(`@/assets/images/products/${this.product.images.folder}/${this.product.preview}`);
         }
      },

      watch: {
         product: {
            handler(value) {
               this.item = { ...value };
               this.item.image = this.imagePath;
               this.item.imageDescription = this.imageDescription;
            },
            immediate: true
         },

         '$route.query': {
            handler() {
               this.minCardHeight = '';
            },
            deep: true
         }
      }
   };
</script>

<style scoped>
   .card:hover {
      min-height: var(--min-card-height);
   }

   .card--active {
      min-height: var(--min-card-height);
   }
</style>
