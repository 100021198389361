<template>
   <main class="main">
      <app-breadcrumbs :routes="routeData" :last-element="currentFilter" />

      <section class="catalog main__catalog">
         <div class="container">
            <h2 class="catalog__section-title section-title">Каталог товаров</h2>

            <product-filters @selected-filter="setSelectedFilter" class="catalog__filter" />

            <ul v-show="products.length" ref="cards" class="catalog__cards cards">
               <li v-for="product in products" :key="product.id" class="cards__item">
                  <product-card
                     :product="product"
                     :class="{
                        'card--sale': product.sections.includes('sale'),
                        'card--new': product.sections.includes('new-products')
                     }"
                     @click="interactWithCard"
                     class="catalog__card"
                  >
                     <template #parameters>
                        <product-params
                           :productId="product.id"
                           :sizes="product.sizes"
                           :colors="product.colors"
                           @selected-params="saveSelectedParams"
                           class="card__parameters"
                        />
                     </template>

                     <template #footer>
                        <card-footer :product="product" />
                     </template>
                  </product-card>
               </li>
            </ul>

            <app-notifications v-if="!products.length" :notification-type="notificationType" />
         </div>
      </section>

      <app-pagination
         v-if="products.length"
         :items-count="productsCount"
         :max-items-count-on-page="maxProductsCountOnPage"
         routeName="Catalog"
      />
   </main>
</template>

<script>
   import AppBreadcrumbs from '@/components/templates/AppBreadcrumbs';
   import ProductFilters from './modules/ProductFilters';
   import ProductCard from '@/components/templates/ProductCard/ProductCard';
   import ProductParams from '@/components/templates/ProductCard/modules/ProductParams';
   import CardFooter from '@/components/templates/ProductCard/modules/CardFooter';
   import AppPagination from '@/components/templates/AppPagination';
   import AppNotifications from '@/components/templates/Notifications/AppNotifications';
   import { removeClassName } from '@/components/pages/Catalog/utils/removeClassName';
   import { mapState, mapActions } from 'vuex';

   export default {
      name: 'Catalog',

      components: {
         AppBreadcrumbs,
         ProductFilters,
         ProductCard,
         ProductParams,
         CardFooter,
         AppPagination,
         AppNotifications
      },

      inject: ['backToTopOfPage'],

      data() {
         return {
            routeData: [
               {
                  name: 'Catalog',
                  query: { page: 1 },
                  title: 'Каталог товаров'
               }
            ],
            selectedFilter: { filter: '', section: '', value: '' },
            maxProductsCountOnPage: 12
         };
      },

      mounted() {
         document.addEventListener('touchstart', this.showProductCard);

         this.backToTopOfPage();
      },

      beforeUnmount() {
         document.removeEventListener('touchstart', this.showProductCard);
      },

      methods: {
         ...mapActions('products', ['fetchProducts']),

         showProductCard(e) {
            const container = this.$refs.cards;

            if (!container.contains(e.target) || container === e.target) {
               removeClassName(container);
            }
         },

         setSelectedFilter(value) {
            this.selectedFilter = value;
         },

         interactWithCard(e) {
            removeClassName(this.$refs.cards);
            e.currentTarget.classList.add('card--active');
         },

         saveSelectedParams(item) {
            const product = this.products.find((product) => product.id === item.id);
            product.selectedParams = item.selectedParams;
         }
      },

      computed: {
         ...mapState('products', ['products', 'productsCount', 'notificationType']),

         currentFilter() {
            const { section, value } = this.selectedFilter;

            return section === 'brands' || section === 'category' ? value : '';
         },

         requestObj() {
            const { section, value, page = 1 } = this.selectedFilter;

            return {
               stateName: 'products',
               query: { page, limit: this.maxProductsCountOnPage, section, value }
            };
         }
      },

      watch: {
         selectedFilter: {
            handler({ filter, page }, { filter: oldFilter, page: oldPage }) {
               if (!page) {
                  this.$router.replace({ name: 'Catalog', query: { filter, page: 1 } });
                  return;
               }

               if (filter !== oldFilter || page !== oldPage) {
                  this.$router.push({ name: 'Catalog', query: { filter, page } });

                  this.fetchProducts(this.requestObj);
               }
            },
            deep: true
         }
      }
   };
</script>
