<template>
   <div class="parameters">
      <div v-for="param in parameters" :key="param.type" class="parameters__inner">
         <button
            :data-id="param.id"
            :data-parameter="param.type"
            :style="{ '--product-color': param.selectedProp.value }"
            @click="showSelectedParam(param)"
            class="parameters__dropdown-header dropdown-header btn"
         >
            {{ param.selectedProp.name }}
         </button>

         <ul
            :data-parameter="param.type"
            :class="{ 'displayed-filter': typeOfSelectedParam === param.type }"
            class="parameters__dropdown-body dropdown-body"
         >
            <li v-for="prop in param.props" :key="prop.name" class="dropdown-body__item">
               <button
                  :style="{ '--product-color': prop.value }"
                  @click="setSelectedParam(param, prop)"
                  class="dropdown-body__btn btn"
               >
                  {{ prop.name }}
               </button>
            </li>
         </ul>
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      name: 'ProductParams',

      props: {
         productId: { type: Number, required: true },
         colors: { type: Array, required: true },
         sizes: { type: Array, required: true }
      },

      emits: {
         'selected-params': (value) => typeof value === 'object'
      },

      data() {
         return {
            selectedParams: { sizes: '', colors: '' },
            parameters: [
               { id: `${Math.random()}`.slice(3, 9), type: 'sizes', props: [], selectedProp: {} },
               { id: `${Math.random()}`.slice(3, 9), type: 'colors', props: [], selectedProp: {} }
            ],
            idOfSelectedParam: '',
            typeOfSelectedParam: ''
         };
      },

      mounted() {
         document.addEventListener('click', this.closeDropdownList);
      },

      beforeUnmount() {
         document.removeEventListener('click', this.closeDropdownList);
      },

      methods: {
         showSelectedParam(param) {
            if (param.props.length) {
               this.typeOfSelectedParam = this.typeOfSelectedParam === param.type ? '' : param.type;
               this.idOfSelectedParam = this.idOfSelectedParam === param.id ? '' : param.id;
            }
         },

         closeDropdownList(e) {
            if (e.target.dataset.id !== this.idOfSelectedParam) {
               this.typeOfSelectedParam = '';
               this.idOfSelectedParam = '';
            }
         },

         fillParamWithData(type, values) {
            const param = this.parameters.find((item) => item.type === type);
            param.props = [...values];
            param.selectedProp = {};

            this.setSelectedParam(param, param.props[0]);
         },

         setSelectedParam(param, prop) {
            if (Object.keys(param.selectedProp).length) {
               param.props.push(param.selectedProp);
            }

            param.selectedProp = prop;
            param.props.splice(param.props.indexOf(prop), 1);
            param.props.sort((a, b) => (a.name < b.name ? -1 : 1));

            this.saveSelectedParams(param, prop);
         },

         saveSelectedParams(param, prop) {
            this.selectedParams[param.type] = prop;

            this.$emit('selected-params', {
               id: this.productId,
               selectedParams: this.selectedParams
            });
         }
      },

      computed: {
         ...mapState('fastBuy', ['product'])
      },

      watch: {
         product: {
            handler(product) {
               if (product.id === this.productId) {
                  const { sizes, colors } = product.selectedParams;

                  this.parameters.forEach((item) => {
                     this.setSelectedParam(item, item.type === 'sizes' ? sizes : colors);
                  });
               }
            },
            deep: true
         },

         sizes: {
            handler(value) {
               this.fillParamWithData('sizes', value);
            },
            immediate: true
         },

         colors: {
            handler(value) {
               this.fillParamWithData('colors', value);
            },
            immediate: true
         }
      }
   };
</script>
